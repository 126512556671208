import { useOnCrmMessageTopic } from 'crm-message-bus/useOnCrmMessageTopic';
import { useCallback } from 'react';
import { logIfIframe } from '../utils/logIfIframe';
export default function CallbackRegistration({
  handler
}) {
  const onMessage = useCallback(data => {
    // The ping topic is handled by the CrmCommandHandler itself, so we don't need to log it
    if (handler.topic !== 'COMMAND_HANDLER_PING') {
      logIfIframe('CallbackRegistration handled message in an iframe', {
        topic: handler.topic
      });
    }
    handler.callback(data);
  }, [handler]);
  useOnCrmMessageTopic(handler.topic, onMessage);
  return null;
}