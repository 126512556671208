import Raven from 'raven-js';
export const logIfIframe = (event, extra) => {
  const isTopWindow = window === window.top;
  if (!isTopWindow) {
    let topWindowDomain = 'unknown domain';
    let topWindowUrl = 'unknown location';
    try {
      var _window$top, _window$top2;
      topWindowDomain = ((_window$top = window.top) === null || _window$top === void 0 ? void 0 : _window$top.location.hostname) || 'unknown domain';
      topWindowUrl = ((_window$top2 = window.top) === null || _window$top2 === void 0 ? void 0 : _window$top2.location.href) || 'unknown url';
    } catch (e) {
      // noop. Protecting against access to the top window location
    }
    const currentUrl = window.location.href;
    const currentDomain = window.location.hostname;
    Raven.capturePageEvent(`CrmCommandHandler: ${event}`, {
      extra: Object.assign({
        isSameDomain: currentDomain === topWindowDomain,
        topWindowDomain,
        currentDomain,
        currentUrl,
        topWindowUrl
      }, extra)
    });
  }
};